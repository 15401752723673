<template>
  <div>
    <v-toolbar
      flat
      color="white"
    >
      <!--  Search Box -->
      <v-form
        @submit.prevent="runSearch"
      >
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="fal fa-search"
          :append-icon="search ? 'fal fa-times-circle' : undefined"
          single-line
          hide-details
          @click:prepend="runSearch"
          @click:append="clearSearch"
        />
      </v-form>

      <v-spacer />
      <v-btn
        color="green lighten-1"
        rounded
        dark
        class="mb-2"
        @click="newItem()"
      >
        New
      </v-btn>

      <!-- New & Edit Dialog Box -->
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                >
                  <v-text-field
                    v-model="editedItem.d_nameFirst"
                    label="First Name"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                >
                  <v-text-field
                    v-model="editedItem.d_nameLast"
                    label="Last Name"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.email"
                    label="Email"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.password"
                    label="Password"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="editedItem.f_admin"
                    label="Site Administrator"
                    color="secondary"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="editedItem.f_access"
                    label="Site Access"
                    color="secondary"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color=""
              text
              @click.native="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="secondary"
              @click.native="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Dialog Box -->
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Delete this record?</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  "{{ editedItem.name }}" will be deleted from the system!
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color=""
              text
              @click.native="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click.native="deleteItem"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="usersStore.users"
      hide-default-footer
      class="elevation-0"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.id }}</td>
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <td class="text-xs">
          {{ props.item.email }}
        </td>
        <td class="justify-end layout px-3">
          <v-icon
            small
            class="mr-4"
            color="secondary"
            @click="editItem(props.item)"
          >
            far fa-pencil-alt
          </v-icon>
          <v-icon
            small
            color="error"
            @click="openDeleteDialog(props.item)"
          >
            fal fa-trash-alt
          </v-icon>
        </td>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="error"
        icon="far fa-exclamation-triangle"
      >
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Users',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
    formTitle() {
      return this.editFlag === -1 ? 'New User' : 'Edit User';
    },
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'name',
          sortable: false,
        },
      ],
      list: [],
      editFlag: -1,
      editedItem: {
        id: '',
        d_nameFirst: '',
        d_nameLast: '',
        email: '',
        password: '',
      },
      defaultItem: {
        id: '',
        d_nameFirst: '',
        d_nameLast: '',
        email: '',
        password: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      /* Store authorized user in store */
      this.$store.dispatch('usersStore/fetch');
    },

    newItem() {
	    this.editFlag = 0;
	    this.dialog = true;
    },

    editItem(item) {
      this.editFlag = 1;
      this.editedItem = { ...item };
      this.dialog = true;
    },

    openDeleteDialog(item) {
      this.editedItem = { ...item };
      this.deleteDialog = true;
    },

    close() {
      this.dialog = false;
      this.deleteDialog = false;
      setTimeout(() => {
        this.editedItem = { ...this.defaultItem };
        this.editFlag = -1;
      }, 300);
    },

    save() {
      if (this.editFlag === 1) {
        this.$store.dispatch('usersStore/update', this.editedItem);
      } else {
        this.$store.dispatch('usersStore/create', this.editedItem);
      }
      this.close();
    },

    deleteItem() {
      const data = {
        id: this.editedItem.id,
      };
      this.$store.dispatch('usersStore/delete', data);
      this.close();
    },

    runSearch() {
      if (this.search) {
        const searchQuery = {
          search: this.search,
        };
        this.$store.dispatch('usersStore/query', searchQuery);
      } else {
        this.$store.dispatch('usersStore/fetch');
      }
    },

    clearSearch() {
      this.search = '';
      this.initialize();
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
